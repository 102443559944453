.list-body{
    height: 100%;
    width: 100%;
}
.list-head{
    /* height: 40px; */
    width: 100%;
    padding-bottom: 26px;
    line-height: 40px;
}
.list-box{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.view-group{
    /* width: 200px; */
    display: inline-block;
}
.view-pic{
    background-image: url('../img/list-pic.svg');
    background-size: 100%;
    height: 20px;
    width: 20px;
    display: inline-block;
    margin-right: 30px;
    cursor: pointer;
}
.view-text{
    background-image: url('../img/list-text.svg');
    background-size: 100%;
    height: 20px;
    width: 20px;
    display: inline-block;
    cursor: pointer;
}

.list-item{
    position: relative;
    display: inline-block;
    width: 19%;
    /* height: 260px; */
    /* margin-right: 2%; */
    background-color: #F5F5F5;
    margin-bottom: 36px;
    vertical-align: top;
    border-radius: 4px;
    overflow: hidden;
}
.list-item:hover{
    box-shadow: 0px 16px 30px rgba(51, 51, 51, 0.33);
}
.list-item .choose-ico{
    display: none;
    position: absolute;
    width: 14px;
    height: 14px;
    top: 10px;
    right: 10px;
    background: url('../img/choice-w.svg');
    background-size: 100%;
    z-index: 9;
}
.list-item.choose .choose-ico{
    display: block;
    background: url('../img/choice.svg');
    background-size: 100%;
}

.list-item:hover .choose-ico{
    display: block;
}

.list-item:nth-child(5n){
    margin-right: 0px;
}
.list-item .cont{
    padding: 15px 16px;
}
.list-item .pic img{
    width: 100%;
    max-height: 148px;
}

.list-item .nopic{
    width: 100%;
    height: 148px;
    background: #FFF;
    position: relative;
}
.list-item .nopic img{
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    /* transform: translate(-10px, -10px); */
    width: 20px;
    height: 20px;
    animation: loadingpic 1.2s linear infinite;
}
@keyframes loadingpic{
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}

.list-item .title{
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 600;
    line-height: 20px;
    color: #121212;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}
.list-item .link{
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #121212;
    padding: 6px 0px;
    display: inline-block;
    vertical-align: top;
    width: calc(100% - 20px);
}
.list-item .ico{
    display: inline-block;
    height: 30px;
    line-height: 30px;
}
.list-item .ico img{
    width: 16px;
    height: 16px;
    vertical-align: middle;
}
.list-item .link span{
    display: inline-block;
    height: 20px;
    line-height: 20px;
    padding-left: 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}
.tags{
    white-space: nowrap;
    overflow-y: scroll;
}

.tags::-webkit-scrollbar {
    width: 0px;
    background: #f5f5f5;
    height: 12px;
}

.choose .tags::-webkit-scrollbar {
    width: 0px;
    background: #898989;
    height: 12px;
}

.tags::-webkit-scrollbar-track,
.tags::-webkit-scrollbar-thumb {
    border-radius: 999px;
    width: 20px;
    border: 5px solid transparent;
}

.tags::-webkit-scrollbar-track {
    box-shadow: 1px 1px 5px #FAFAFA  inset;
}

.tags::-webkit-scrollbar-thumb {
    width: 20px;
    min-height: 20px;
    background-clip: content-box;
    box-shadow: 0 0 0 5px #C1C1C1 inset
}

.tags::-webkit-scrollbar-corner {
    background: #ffffff;
}

.tags .tag-item{
    display: inline-block;
    background: rgba(255, 255, 255);
    border: 1px solid #EBEBEB;
    opacity: 1;
    border-radius: 13px;
    padding: 4px 8px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 17px;
    color: #121212;
    margin-right: 2px;
    cursor: pointer;
}

.type-text .list-item{
    display: inline-block;
    width: 100%;
    /* height: 42px; */
    padding: 4px;
    background-color: #FFFFFF;
    margin-right: 0px;
    margin-bottom: 0px;
}
.type-text .list-item.choose{
    background-color: #898989;
}
.type-text .list-item:hover{
    background-color: #F5F5F5;
}
.type-text .list-item .pic{
    display: inline-block;
    width: 74px;
    height: 42px;
}
.type-text .list-item .pic img{
    width: 100%;
    height: 100%;
}

.type-text .list-item .nopic{
    width: 74px;
    height: 42px;
    background: #FFF;
    position: relative;
    display: inline-block;
}
.type-text .list-item .nopic img{
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 10px);
    /* transform: translate(-10px, -10px); */
    width:20px;
    height: 20px;
    animation: loadingpic 1.2s linear infinite;
}

.type-text .list-item .cont{
    display: inline-block;
    padding: 0px;
    height: 42px;
    line-height: 42px;
    vertical-align: top;
    padding-left: 16px;
    width: calc(100% - 170px);
}

.type-text .list-item .title{
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #121212;
    display: inline-block;
    height: 42px;
    line-height: 42px;
    vertical-align: top;
    padding-left: 9px;
}
.type-text .list-item .link{
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #121212;
    margin-left: 25px;
    height: 42px;
    line-height: 42px;
    padding: 0px;
    padding-left:20px;
    vertical-align: top;
}
.type-text .list-item .ico{
    display: inline-block;
    height: 42px;
    line-height: 37px;
    float: left;
}
.type-text .list-item .ico img{
    width: 16px;
    height: 16px;
    vertical-align: middle;
}
.type-text .list-item .link span{
    display: inline-block;
    height: 20px;
    line-height: 20px;
    padding-left: 6px;
}
.type-text .tags{
    white-space: nowrap;
    float: right;
}
.type-text .tag-item{
    display: inline-block;
    background: rgba(255, 255, 255, 0.39);
    border: 1px solid #EBEBEB;
    opacity: 1;
    border-radius: 13px;
    padding: 4px 8px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 17px;
    color: #121212;
}

.zw {
    height: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
    background: none !important;
    box-shadow: none !important;
    border: none !important;
}

.zw::after,
.zw::before {
    display: none !important;
}
.mfbtn{
    background: #111111;
    padding: 0px 20px;
    color: #FFFFFF;
    border: 0px;
    cursor: pointer;
}

.btns span{
    margin-right: 36px;
}
.delbtn,.copybtn,.backbtn{
    cursor: pointer;
}
.pasteBox{
    display: none;
}
.ant-modal-footer{
    border:none; 
}
.ant-modal-header{
    border:none; 
}
.ant-modal-title{
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: 600;
}
.ant-btn:hover, .ant-btn:focus{
    border-color: #111111;
    color: #111111;
}
.ant-btn-dark{
    border-radius: 4px;
    background-color: #111111;
    border-color: #111111;
    color: #FFFFFF;
}
.ant-btn-dark:hover, .ant-btn-dark:focus{
    background-color: #111111;
    border-color: #111111;
    color: #FFFFFF;
}
.ant-btn-default{
    border-radius: 4px;
    border-color: #111111;
    color: #111111;
}
.ant-btn-default:hover .ant-btn-default:focus{
    border-color: #111111;
    color: #111111;
}

.model-box{
    width: 100%;
}
.model-box .model-item{
    width: 100%;
    margin-bottom: 20px;
}
.model-box .model-item label{
    display: block;
    width: 100%;
    height: 20px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 20px;
    color: #111111;
    margin-bottom: 8px;
}
.model-box .model-item .model-input{
    width: 100%;
    min-height: 45px;
    background: #F5F5F5;
    border-radius: 4px;
    padding:12px;
}
.model-input input{
    width: 100%;
    margin-bottom: 8px;
    background: transparent;
}

.ant-tag{
    margin-bottom: 8px;
}


@media screen and (max-width:1680px){
    .list-item{
        width: 19%;
    }
}
@media screen and (max-width:1440px){
    .list-item{
        width: 24%;
    }
}
@media screen and (max-width:1280px){
    .list-item{
        width: 24%;
    }
}
@media screen and (max-width:1024px){
    .list-item{
        width: 24%;
    }
}