@import '~antd/dist/antd.css';
*{
    margin: 0;
    padding: 0;
    border:0px;
}
input {
    outline: none;
    border: 0px;
    padding: 0;
}

li {
    list-style: none;
}
a,a:hover{
    color: #000;
    text-decoration: none;
}
ul{
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    width: 16px;
    background: #ffffff;
    height: 14px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
    border-radius: 999px;
    width: 20px;
    border: 5px solid transparent;
}

::-webkit-scrollbar-track {
    box-shadow: 1px 1px 5px #FAFAFA  inset;
}

::-webkit-scrollbar-thumb {
    width: 20px;
    min-height: 20px;
    background-clip: content-box;
    box-shadow: 0 0 0 5px #C1C1C1 inset
}

::-webkit-scrollbar-corner {
    background: #ffffff;
}

.main {
    display: inline-block;
    height: 100vh;
    width: calc(100vw - 401px);
    overflow: hidden;
    vertical-align: top;
}

.header {
    width: 100%;
    height: 60px;
    position: relative;
    z-index: 1;
}

.logoBox {
    width: 100%;
    height: 60px;
    display: inline-block;
    color: #FFFFFF;
    background: url('../img/logo.svg');
    background-repeat: no-repeat;
    background-size: 200px;
    background-position: 26px center;
    position: relative;
}


.logoBox.black {
    background-color: #000;
}

.logoBox i{
    width: 22px;
    height: 22px;
    border-radius: 50%;
    position: absolute;
    top: 19px;
    right: 18px;
    background: #ffffff;
    background-image: url(../img/deng.svg);
    background-size: 20px;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.logoBox i.close{
    background-image: url(../img/right.svg);
    transform: rotate(180deg);
    background-size: 6px;
}

/* .logoBox .fontBox {
    display: inline-block;
    height: 60px;
    line-height: 60px;
    vertical-align: top;
} */

.searchBox {
    display: inline-block;
    width: 100%;
    vertical-align: top;
    height: 60px;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
}

.searchBox .search-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url('../img/search.svg');
    background-size: 100%;
    margin: 20px 16px 20px 22px;
    cursor: pointer;
}

.searchBox .search-input {
    display: inline-block;
    width: calc(100% - 160px);
    vertical-align: top;
    height: 36px;
    margin-top: 12px;
    padding-left: 4px;
}
.userBox {
    position: absolute;
    right: 90px;
    top: 0;
    width: 90px;
    height: 60px;
    background: #ffffff;
    text-align: center;
    font-size: 14px;
    font-family: Gotham;
    font-weight: bold;
    line-height: 58px;
    color: #111111;
}
.loginBox {
    position: absolute;
    right: 0px;
    top: 0;
    width: 90px;
    height: 60px;
    background: #111111;
    text-align: center;
    font-size: 14px;
    font-family: Gotham;
    font-weight: bold;
    line-height: 58px;
    color: #FFFFFF;
    cursor: pointer;
}
.menu{
    height: calc(100vh - 167px);
    overflow-y: scroll;
}
.menuBox {
    width: 400px;
    display: inline-block;
    height: calc(100vh - 92px);
    position: relative;
}
.menu-item-text{
    background: #FFF;
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    padding: 14px 0 14px 38px;
    white-space: nowrap;
    cursor: pointer;
}
.menu-item-text:hover{
    background:#F5F5F5;
}
.menu-item-text.selected{
    background: #000;
    color: #FFF!important;
}
.menu-item-text.selected a{
    color: #FFF!important;
}
.selected .menu-item a{
    color:#FFF;
}
.menu-item-box i {
    display: inline-block;
    width: 10px;
    height: 10px;
}

.menu-item {
    display: inline-block;
    cursor: pointer;
}

.item-list {
    width: 100%;
    padding: 0;
    display: none;
}
.item-list:last-child{
    padding: 0px;
}
.item-list.open{
    display: inline-block;
}
.first-list{
    /* overflow-x: scroll; */
    padding-top: 15px !important;
}

i.tag-icon {
    display: inline-block;
    background-image: url('../img/tag.svg');
    width: 20px;
    height: 20px;
    background-size: 100%;
    margin: 0 16px 0 20px;
    vertical-align: text-bottom;
}
.selected .menu-item i.tag-icon {
    background-image: url('../img/tag-w.svg');
}
i.collection-icon {
    display: inline-block;
    background-image: url('../img/folder.svg');
    width: 20px;
    height: 18px;
    background-size: 100%;
    vertical-align: text-bottom;
}
.selected .menu-item i.collection-icon {
    background-image: url('../img/folder-w.svg');
}

i.delete-icon {
    display: inline-block;
    background-image: url('../img/delete.svg');
    width: 20px;
    height: 20px;
    background-size: 100%;
    vertical-align: text-bottom;
}
.selected .menu-item i.delete-icon {
    background-image: url('../img/delete-w.svg');
}

i.reback-icon {
    display: inline-block;
    background-image: url('../img/reback.svg');
    width: 20px;
    height: 20px;
    background-size: 100%;
    vertical-align: text-bottom;
}
i.copy-icon {
    display: inline-block;
    background-image: url('../img/copy.svg');
    width: 20px;
    height: 20px;
    background-size: 100%;
    vertical-align: text-bottom;
}
.menu-item i{
    margin: 0 16px 0 20px;
}
.box-btm {
    position: absolute;
    bottom: 0px;
    width: calc(100% - 16px);
}

.menu-item .num {
    float: right;
}

.menu-more {
    display: inline-block;
    background-image: url('../img/drop.svg');
    width: 10px;
    height: 10px;
    background-size: 100%;
}

.selected .menu-more {
    background-image: url('../img/drop-w.svg');
}

.open.menu-more {
    transform: rotateZ(90deg);
}

.list-main {
    width: 100%;
    display: inline-block;
    height: calc(100vh - 60px);
    vertical-align: top;
    padding: 38px 20px 20px 76px;
    overflow-y: scroll;
}

.footer {
    position: fixed;
    bottom: 0px;
    width: calc(100% - 40px);
}

.copyright {
    text-align: center;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 20px;
    color: #FFFFFF;
    padding: 20px;
}
.right{
    float: right;
}

.left{
    float: left
    ;
}

.mouse-menu{
    background-color: #FFFFFF;
    width: 260px;
    padding: 14px 0px;
    display: none;
    position: fixed;
    z-index:99;
    top: 0;
    left: 0;
}
.mouse-menu .item{
    padding: 12px 18px;
    cursor: pointer;
}
.mouse-menu .item:hover{
    background-color: #F5F5F5;
}
.mouse-menu.open{
    display: block;
}
.hide{
    display: none;
}
.ant-modal-content{
    padding:30px 6px 50px;
}

.mini.main {
    width: 100%;
}
.mini.menuBox{
    width: 116px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9;
}
.mini .logoBox{
    background-size: 37px;
    background-image: url(../img/logo-icon.svg);
    background-position: 26px 12px;
}
.mini .list-main{
    padding: 38px 50px;
}
.mini .menu{
    display: none;
}
.mini .header{
    padding-left: 116px;
}
.mini .list-item{
    width: 14%;
}


@media screen and (max-width:1680px){
    .main {
        width: calc(100vw - 321px);
    }
    .menuBox{
        width: 320px;
    }
    .list-main{
        padding: 26px 10px 20px 23px;
    }

    .mini .list-item{
        width: 16%;
    }
}
@media screen and (max-width:1440px){
    .main {
        width: calc(100vw - 301px);
    }
    .menuBox{
        width: 300px;
    }
    .list-main{
        padding: 26px 10px 20px 23px;
    }
    .mini .list-item{
        width: 19%;
    }
}
@media screen and (max-width:1280px){
    .main {
        width: calc(100vw - 261px);
    }
    .menuBox{
        width: 260px;
    }
    .logoBox{
        background-size: 150px;
    }
    .list-main{
        padding: 26px 10px 20px 23px;
    }
    .mini .list-item{
        width: 19%;
    }
}
@media screen and (max-width:1024px){
    .main {
        width: 100%;
    }
    .menuBox{
        width: 116px;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9;
    }
    .logoBox{
        background-size: 37px;
        background-image: url(../img/logo-icon.svg);
        background-position: 26px 12px;
    }
    .list-main{
        padding: 38px 50px;
    }
    .menu{
        display: none;
    }
    .header{
        padding-left: 116px;
    }

    .mini.menuBox{
        width: 280px;
        height: 100vh;
        background: #FFF;
    }
    .mini .logoBox{
        background-image: url('../img/logo.svg');
        background-size: 200px;
        background-position: 26px center;
    }
    .mini .list-main{
        padding: 38px 50px;
    }

    .mini .menu{
        display: block;
        background-color: #FFF;
    }
    .mini .header{
        padding-left: 280px;
    }
    .mini .list-item{
        width: 24%;
    }
}

