.main-bg{
    position: fixed;
    z-index: 0;
    width: 100%;
    height: 100%;
    background: rgba(17, 17, 17, 0.39);
}
.login-bg{
    width: 100vw;
    background: url('../img/login-bg.jpeg');
    background-position: 0% 0%;
    background-size:100%;
    animation: loginBgMove 60s linear infinite;
}
@keyframes loginBgMove{
    0% {background-position:0% 0%;}
    99.99% {background-position:0% 90.45%;}
    100% {background-position:0% 0%;}
}

.formBox{
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 400px;
    width: calc(100% - 40px);
    margin: 0 auto 0;
    padding: 20px;
    text-align: center;
    transform: translate(-50%,-50%);
}
.formBox h2{
    font-size: 35px;
    font-family: Gotham;
    font-weight: bold;
    line-height: 43px;
    color: #FFFFFF;
    text-align: center;
}
.form{
    width: 100%;
}
.form .form-item{
    width: 100%;
    margin-bottom: 14px;
}
.form .form-input{
    width: 100%;
    height: 45px;
    border-radius: 4px;
    border: 1px solid #fff;
    margin: 0px;
    padding: 0 15px;
    line-height: 45px;
} 

.form .checkbox{
    text-align: left;
}
.form .form-checkbox{
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
    background:url('../img/choose.svg');
    background-size: 100%;
} 
.form .form-checkbox.checked{
    background: url('../img/choose-click.svg');
    background-size: 100%;
} 
.form .checkbox span{
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 20px;
    color: #FFFFFF;
}

.form .yzminput{
    width: calc(100% - 120px);
    display: inline-block;
    margin-right: 20px;
}
.form .yzm{
    width:100px;
    height: 45px;
    display: inline-block;
    vertical-align: top;
    border-radius: 4px;
    cursor: pointer;
}
.form .submit{
    width: 100%;
    height: 45px;
    background: #111111;
    box-shadow: 0px 0px 41px rgba(255, 255, 255, 0.51);
    opacity: 1;
    border: 0px;
    color: #FFFFFF;
    border-radius: 4px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    line-height: 20px;
    color: #FFFFFF;
    cursor: pointer;
}
.error{
    border: 1px solid #dc0000!important;
}